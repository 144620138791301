export const BASE_URL = (function (): string {
  switch (process.env.REACT_APP_TARGET_API) {
    case "local":
      return "http://localhost:7587/api/v1";
    case "dev":
      return "https://sakani-be-dev.sefrone.dev/api/v1";
    case "edge":
      return "https://sakani-be-edge.sefrone.dev/api/v1";
    case "main":
      return "https://api.sakani.dz/api/v1";
    default:
      return "https://sakani-be-dev.sefrone.dev/api/v1";
  }
})();

export const ENDPOINTS = {
  AUTH_LOGIN_ENDPOINT: BASE_URL + "/apiAuth/account/sakani/login",
  AUTH_OTP_CODE_ENDPOINT: BASE_URL + "/apiAuth/account/sakani/login/otpCode",
  AUTH_OTP_VERIFY_ENDPOINT: BASE_URL + "/apiAuth/account/sakani/login/verify/",
  AUTH_VERIFY_ENDPOINT: BASE_URL + "/apiAuth/account/sakani/login/verify/",
  AUTH_LOGOUT_ENDPOINT: BASE_URL + "/apiAuth/account/sakani/logout/",
  AUTH_UPDATE_PASSWORD_ENDPOINT: BASE_URL + '/apiAuth/account/sakani/own/credentials',

  TENANTS_GET_CONNECTED_LIST_ENDPOINT: BASE_URL + "/tenants/getConnectedTenants",
  TENANTS_GET_TENANT_DETAILED_INFOS_ENDPOINT: BASE_URL + "/tenants/getTenantDataById/", // {worksapceId}/{accountId}
  TENANTS_GET_TENANT_INVOICES_ENDPOINT: BASE_URL + "/payments/transactions/", // {worksapceId}/{accountId}

  DOWNLOAD_INVOICE: BASE_URL + "/payments/generatePdfFile/",

  CAISSE_CREATE_TRANSACTION_ENDPOINT: BASE_URL + "/registries/payment/",
  CAISSE_QUOTE_TRANSACTION_ENDPOINT: BASE_URL + "/registries/payment/quote/", // {tenantId}

  TENANT_RUBRIQUE_GET_LIST_ENDPOINT: BASE_URL + "/rubriques/list/tenant/",
  RUBRIQUE_GET_LIST_ENDPOINT: BASE_URL + "/rubriques/workspace/",

  CASH_REGISTER_GET_LIST_ENDPOINT: BASE_URL + "/registries/workspace/", // {workspaceId}
  CASH_REGISTER_STOP_ENDPOINT: BASE_URL + "/registries/log/",
  CASH_REGISTER_TIME_FRAME_ENDPOINT: BASE_URL + "/registries/timeframe/log/",
  CASH_REGISTER_RUBRIQUE_ENDPOINT: BASE_URL + "/registries/rubrique/",
  CASH_REGISTER_EXPORT_LOGS_ENDPOINT: BASE_URL + "/registries/export/",

  REPORT_ENDPOINT: BASE_URL + "/registries/report/",

  PING_ENDPOINT: BASE_URL + "/ping",

  USERS_GET_LIST_ENDPOINT: BASE_URL + '/apiAuth/account/sakani/workspace/',

  USER_INFO_UPDATE_ENDPOINT: BASE_URL + '/userInfo',
  USER_INFO_GET_ENDPOINT: BASE_URL + '/userInfo',
  USER_INFO_GET_LIST_ENDPOINT: BASE_URL + '/userInfo/', // ${workspaceId}/list
};
