export const RTL_LANGUAGES = ["ar"];

export const LANGUAGES = ["en", "fr", "ar"] as const;

export const REGEX = {
  PHONE: /^(\+213|0)(5|6|7)[0-9]{8}$/,
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
} as const;

export const CAISSETYPE = ["cash", "cheque", "bankDeposit", "bankTransfer", "tpe", "brigadeDeRecouvrement"] as const;

export const STATE_DATE_TYPE = {
  DAY: "day",
  MONTH: "month",
  YEAR: "year",
} as const;

export type StateDateType = typeof STATE_DATE_TYPE[keyof typeof STATE_DATE_TYPE];