import { useFormik } from "formik";
import { StatsRequestBody } from "../../types/request/StatsRequest";
import { ReactComponent as BackArrow } from "../../assets/icons/backArrow.svg";
import Daypicker from "./Daypicker";
import { useNavigate } from "react-router-dom";
import InputCheckbox from "../../components/Inputs/InputCheckbox";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { useExportLogs } from "../../api/stats";
import Spinner from "../../components/ui/Spinner";
import { useAuth } from "../../contexts/Auth";
import { createExportSchema } from "./validator";
import { useState } from "react";
import { STATE_DATE_TYPE, StateDateType } from "../../constants";

export default function Stats(): React.JSX.Element {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { selectedCaisse, selectedWorkspace } = useAuth();

    const { isLoading, mutate } = useExportLogs({
        onSuccess: (data, values) => {
            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0];
            const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `exported_logs_${values.type}_${formattedDate}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    });

    const [dateType, setDateType] = useState<StateDateType>("day");

    const initialValues: StatsRequestBody = {
        startDate: undefined,
        endDate: undefined,
        journalCaisse: false,
        bordereauCaisse: false,
        ventilationFiscale: false,
        recapRecouvrement: false,
        etatRecouvrement: false,
        etatVersement: false,
        registreCaisse: false,
        duplicataQuitance: false,
        etatDesConstatations: false,
        etatRecouvrementParCaisse: false,
        recapRecouvrementParCaisse: false,
    };

    const formik = useFormik<StatsRequestBody>({
        initialValues,
        validationSchema: createExportSchema(t),
        onSubmit: values => {
            if (!values.startDate || !values.endDate) {
                formik.setErrors({
                    startDate: t("required"),
                    endDate: t("required"),
                });
                return;
            }

            for (const key in values) {
                if (key.endsWith("ParCaisse") || key.endsWith("Date") || !values[key as keyof StatsRequestBody]) continue;

                mutate({
                    workspaceId: selectedWorkspace?.workspaceId || "",
                    registerId: selectedCaisse?.id || "",
                    type: key,
                    isCaisse: !!values[key + "ParCaisse" as keyof StatsRequestBody],
                    startDate: values.startDate ? new Date(values.startDate.getTime() + (60 * 60 * 1000)).toISOString() : "",
                    endDate: values.endDate ? new Date(values.endDate.getTime() + (60 * 60 * 1000)).toISOString() : "",
                });
            }
        }
    });

    return (
        <article className="flex items-start">
            <BackArrow
                className="cursor-pointer rtl:rotate-180"
                onClick={() => navigate("/dashboard")}
            />
            <form
                onSubmit={formik.handleSubmit}
                className="flex items-center flex-col gap-10 w-[calc(100%-40px)]">
                <section className="flex items-start gap-4">
                    {Object.values(STATE_DATE_TYPE).map((type) => (
                        <PrimaryButton
                            key={type}
                            type="button"
                            onClick={() => {
                                setDateType(type);
                                formik.setFieldValue("startDate", undefined);
                                formik.setFieldValue("endDate", undefined);
                            }}
                            className={dateType !== type ? "!bg-white !border !border-primaryGreen !text-primaryGreen" : ""}
                        >
                            {t(type + "StateType")}
                        </PrimaryButton>
                    ))}

                    <Daypicker
                        onChangeDate={(date) => {
                            formik.setFieldValue("startDate", date);
                            if (!date) return;
                            switch (dateType) {
                                case "day":
                                    formik.setFieldValue("endDate", date);
                                    break;
                                case "month":
                                    formik.setFieldValue("endDate", new Date(date.getFullYear(), date.getMonth() + 1, 0));
                                    break;
                                case "year":
                                    formik.setFieldValue("endDate", new Date(date.getFullYear(), 11, 31));
                                    break;
                            }
                        }}
                        dateType={dateType}
                        value={formik.values.startDate}
                        error={formik.errors.startDate}
                        setError={(error) => formik.setFieldError("startDate", error)}
                    />
                </section>
                <section className="space-y-6">
                    <InputCheckbox
                        label="journalCaisse"
                        name="journalCaisse"
                        onChange={formik.handleChange}
                        value={formik.values.journalCaisse}
                    />
                    <InputCheckbox
                        label="bordereauCaisse"
                        name="bordereauCaisse"
                        onChange={formik.handleChange}
                        value={formik.values.bordereauCaisse}
                    />
                    <InputCheckbox
                        label="ventilationFiscale"
                        name="ventilationFiscale"
                        onChange={formik.handleChange}
                        value={formik.values.ventilationFiscale}
                    />

                    <div className="space-y-2">
                        <InputCheckbox
                            label="recapRecouvrement"
                            name="recapRecouvrement"
                            onChange={(e) => {
                                formik.handleChange(e);
                                if (!e.target.checked)
                                    formik.setFieldValue("recapRecouvrementParCaisse", false);
                            }}
                            value={formik.values.recapRecouvrement}
                        />
                        <InputCheckbox
                            label="parCashier"
                            name="recapRecouvrementParCaisse"
                            onChange={(e) => {
                                formik.handleChange(e);

                                if (e.target.checked)
                                    formik.setFieldValue("recapRecouvrement", true);
                            }}
                            value={formik.values.recapRecouvrementParCaisse}
                            className="ps-10"
                        />
                    </div>

                    <div className="space-y-2">
                        <InputCheckbox
                            label="etatRecouvrement"
                            name="etatRecouvrement"
                            onChange={(e) => {
                                formik.handleChange(e);
                                if (!e.target.checked)
                                    formik.setFieldValue("etatRecouvrementParCaisse", false);
                            }}
                            value={formik.values.etatRecouvrement}
                        />
                        <InputCheckbox
                            label="parCashier"
                            name="etatRecouvrementParCaisse"
                            onChange={(e) => {
                                formik.handleChange(e);

                                if (e.target.checked)
                                    formik.setFieldValue("etatRecouvrement", true);
                            }}
                            value={formik.values.etatRecouvrementParCaisse}
                            className="ps-10"
                        />
                    </div>

                    <InputCheckbox
                        label="registreCaisse"
                        name="registreCaisse"
                        onChange={formik.handleChange}
                        value={formik.values.registreCaisse}
                    />
                    <InputCheckbox
                        label="duplicataQuitance"
                        name="duplicataQuitance"
                        onChange={formik.handleChange}
                        value={formik.values.duplicataQuitance}
                    />

                    <InputCheckbox
                        label="etatDesConstatations"
                        name="etatDesConstatations"
                        onChange={formik.handleChange}
                        value={formik.values.etatDesConstatations}
                    />
                </section>


                <PrimaryButton
                    type="submit"
                    disabled={isLoading}
                >

                    {isLoading ? <Spinner /> : t("export")}
                </PrimaryButton>
            </form>
        </article>
    );
}